<template>
  <div
    class="cart-item"
    @mouseenter="$emit('setActiveBike', item)"
  >
    <a
      v-if="productUrl"
      :href="productUrl"
      class="title"
    >{{ item.product.name }}</a>
    <img
      v-if="item.configured_variant?.image?.url || item?.product?.image?.url || false"
      :alt="item.product?.name ?? ''"
      :src="item.configured_variant?.image?.url || item?.product?.image?.url"
      class="image"
    >
    <div class="price" v-if="item.prices?.regular_price?.value > item.prices?.row_total_including_tax?.value">
      <span
        v-if="storeCode"
        :class="{
          'old': item.prices?.regular_price?.value
        }"
      >
        {{ $formatPriceWithCurrency(item.prices?.regular_price?.value, item.prices?.regular_price?.currency, storeCode) }}
      </span>
      <span v-if="storeCode && (item.prices?.regular_price?.value > item.prices?.row_total_including_tax?.value)">
        {{ $formatPriceWithCurrency(item.prices?.row_total_including_tax?.value, item.prices?.row_total_including_tax?.currency, storeCode) }}
      </span>
    </div>

    <div class="price" v-if="item.prices?.total_item_discount?.value > 0">
      <span
          v-if="storeCode"
          :class="{
          'old': item.prices.total_item_discount.value
        }"
      >
        {{ $formatPriceWithCurrency(item.prices?.row_total_including_tax?.value, item.prices?.row_total_including_tax?.currency, storeCode) }}
      </span>
      <span v-if="storeCode && item.prices?.total_item_discount?.value">
        {{ $formatPriceWithCurrency(item.prices?.row_total_including_tax?.value - item.prices?.total_item_discount?.value, item.prices?.row_total_including_tax?.currency, storeCode) }}
      </span>
    </div>

    <div class="price" v-if="item.prices?.total_item_discount?.value === 0 && item.prices?.regular_price?.value <= item.prices?.row_total_including_tax?.value">
      <span v-if="storeCode">
        {{ $formatPriceWithCurrency(item.prices?.row_total_including_tax?.value, item.prices?.row_total_including_tax?.currency, storeCode) }}
      </span>
    </div>

    <div
      v-if="item.configurable_options"
      class="attributes"
    >
      <div
        v-for="option in item.configurable_options"
        :key="option.configurable_product_option_uid"
        class="attribute"
      >
        <span class="label">{{ option.option_label }}</span>
        <span
          class="value"
          v-html="$renderSwatchValue(option.value_label)"
        />
      </div>
    </div>
    <div class="quantity">
      <button
        :disabled="item.quantity < 2 || isLoading"
        @click="updateCartItemQuantity(item, item.quantity - 1)"
      >
        -
      </button>
      <span>{{ item.quantity }}</span>
      <button
        :disabled="isLoading"
        @click="updateCartItemQuantity(item, item.quantity + 1)"
      >
        +
      </button>
    </div>
    <Icon
      class="remove"
      name="mdi-light:delete"
      @click="updateCartItemQuantity(item, 0)"
    />
  </div>
</template>

<script setup>
  import { computed, onMounted } from "vue";

  import updateCartItems from "../../apollo/queries/updateCartItems.gql";
  import removeItemFromCart from "../../apollo/queries/removeItemFromCart.gql";

  defineEmits(["setActiveBike"]);

  const props = defineProps({
    item: {
      type: Object,
      default: () => {
      },
    },
  });


  const cart = useCart();
  const storeCode = useStoreCode();
  const runtimeConfig = useRuntimeConfig();
  const isLoading = useState(() => false);
  const shopBaseUrl = useState('shopBaseUrl', () => null);
  const productUrl = computed(() => shopBaseUrl.value + '/' + props.item.product?.url_key);
  const checkoutDisabled = useCheckoutDisabled();
  const { useRecaptchaMutation } = useRecaptcha();
  const { $toast, $renderSwatchValue, $formatPriceWithCurrency } = useNuxtApp();

  onMounted(() => {
    if (runtimeConfig?.public?.SHOP?.BASE_URL && storeCode?.value) {
      shopBaseUrl.value = runtimeConfig.public.SHOP.BASE_URL.replace('store_code', storeCode.value);
    }
  })

  async function updateCartItemQuantity(item, newQuantity) {
    let input;
    let mutation;

    if (newQuantity > 0) {
      mutation = updateCartItems;
      input = {
        input: {
          cart_id: cart.value.id,
          cart_items: [
            {
              cart_item_uid: item.uid,
              quantity: newQuantity,
            },
          ],
        },
      };
    } else {
      mutation = removeItemFromCart;
      input = {
        input: {
          cart_id: cart.value.id,
          cart_item_uid: item.uid,
        }
      }
    }

    isLoading.value = true;
    const { mutate, error } = await useRecaptchaMutation(
      mutation,
      input
    );
    const productName = item.product.name;

    try {
      const result = await mutate();

      if (result.data) {
        isLoading.value = false;
        checkoutDisabled.value = false;

        if (item.quantity < newQuantity) {
          $toast.success(t("cart.components.item.toast_added", { product_name: productName, }));
        } else {
          $toast.success(t("cart.components.item.toast_removed", { product_name: productName, }));
        }

        cart.value = result.data?.updateCartItems?.cart || result.data?.removeItemFromCart?.cart || null;
      }
    } catch (error) {
      isLoading.value = false;
      throw error;
    }

    if (!cart.value) {
      isLoading.value = false;
      return $toast.error(t("cart.components.item.toast_error", { product_name: productName, error_value: error.value }))
    }
  }
</script>

<style lang="scss">
  .cart-item {
    display: grid;
    align-items: center;
    padding: 30px;
    position: relative;
    margin-bottom: 10px;
    box-sizing: border-box;
    background: var(--cart-item-bg, $cart-item-bg);
    margin-top: var(--cart-item-mobile-image-margin-top);
    border: 1px solid var(--cart-item-border, $cart-item-border);
    grid-template-areas: var(--cart-item-grid-template-areas-mobile);

    @include min-screen('medium') {
      margin: 0 0 20px;
      margin-right: calc(var(--cart-item-remove-desktop-right) * -1);
      grid-row-gap: 15px;
      align-items: center;
      grid-column-gap: 30px;
      grid-template-columns: var(--cart-item-grid-template-columns);
      grid-template-areas: var(--cart-item-grid-template-areas-desktop);
    }

    &:hover {
      @include min-screen('medium') {
        border-color: var(--cart-item-border-hover, $cart-item-border-hover);
      }
    }

    img {
      grid-area: image;
      object-fit: contain;
      max-width: var(--cart-item-mobile-image-width, 100%);
      margin-top: calc(var(--cart-item-mobile-image-margin-top, 0) * -1);
      margin-bottom: 30px;

      @include min-screen('medium') {
        margin: 0;
        max-width: 120px;
      }
    }

    a.title {
      grid-area: title;
      margin-bottom: 15px;
      font-size: var(--cart-item-title-font-size, 20px);
      font-weight: var(--cart-item-title-font-weight-mobile, 400);
      text-transform: var(--cart-item-title-text-transform, none);
      font-family: var(--title-font, --primary-font, $default-font);
      color: var(--black, $black);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      @include min-screen('medium') {
        margin-bottom: 0;
        top: var(--cart-item-title-transform-top, initial);
        left: var(--cart-item-title-transform-left, initial);
        font-weight: var(--cart-item-title-font-weight-desktop, 600);
        position: var(--cart-item-title-transform-position, initial);
      }
    }

    .price {
      grid-area: price;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 3px;
      font-size: 18px;
      margin-bottom: 20px;
      justify-content: var(--cart-item-mobile-price-justify-content, start);

      @include min-screen('medium') {
        margin-bottom: 0;
      }

      .old {
        font-weight: 400;
        text-decoration: line-through;
      }

      span {
        display: block;
        width: 100%;
        font-weight: 600;
        text-align: right;
      }
    }

    .attributes {
      grid-area: attributes;
      margin-bottom: 30px;

      @include min-screen('medium') {
        margin-bottom: 0;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
      }

      @include min-screen('large') {
        align-items: center;
        justify-content: space-between;
      }

      @include min-screen('xlarge') {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .attribute {
        display: grid;
        margin-bottom: 10px;
        grid-template-columns: 1fr 1fr;

        @include min-screen('medium') {
          grid-template-columns: 1fr;
        }

        .label {
          opacity: 0.4;
          font-weight: 500;
          color: var(--black, $black);

          @include min-screen('medium') {
            margin-bottom: 5px;
          }
        }

        .value {
          font-weight: 700;
          word-break: break-word;
          color: var(--black, $black);
        }
      }
    }

    .quantity {
      grid-area: quantity;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;
      padding: 12px;
      grid-gap: 10px;
      min-width: 120px;
      line-height: 1;
      font-size: 20px;
      user-select: none;
      border: 1px solid var(--medium-grey, $medium-grey);

      button {
        border: 0;
        padding: 0;
        outline: none;
        cursor: pointer;
        line-height: 1;
        font-size: 24px;
        background: none;
        color: currentColor;
        font-weight: normal;

        &[disabled] {
          cursor: default;
          opacity: .2;
        }
      }
    }

    .remove {
      grid-area: remove;
      top: -10px;
      right: -10px;
      opacity: 0.5;
      color: $black;
      font-size: 24px;
      cursor: pointer;
      margin-left: auto;
      position: absolute;

      @include min-screen('medium') {
        top: var(--cart-item-remove-desktop-top, unset);
        right: var(--cart-item-remove-desktop-right, unset);
        transform: var(--cart-item-remove-desktop-transform, none);
        position: var(--cart-item-remove-desktop-position, relative);
        grid-area: var(--cart-item-remove-desktop-grid-area, remove);
      }

      &:hover {
        color: var(--error, $error);
      }
    }
  }
</style>
